<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="text-center">
                        <b-col cols="11">
                            <!-- media -->
                            <b-media no-body>
                                <b-media-body class="mt-75 ml-75">
                                    <b-row class="justify-content-center">
                                        <b-col cols="12" sm="4">
                                            <!-- upload button -->

                                            <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                            <!--/ upload button -->
                                        </b-col>
                                    </b-row>
                                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                </b-media-body>
                            </b-media>
                            <!--/ media -->
                        </b-col>
                    </b-row>
                    <b-row class="mt-3 justify-content-center">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="3">
                            <validation-provider #default="validationContext" name="name" rules="required">
                                <b-form-group label="Pack Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="2">
                            <b-form-checkbox v-model="form.is_public" value="1" class="custom-control-success mt-2"> Is Public </b-form-checkbox>
                        </b-col>
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ stickerId ? "update" : "add" }}
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useStickerList from "./useStickerList";
import useFile from "../file/useFile";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BFormCheckbox,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            name: "",
            is_public: 0,
            file_batch_id: RANDOM_TOKEN,
        };

        const stickerId = ref(null);

        const form = ref(JSON.parse(JSON.stringify(blankForm)));

        if (router.currentRoute.params.id) {
            stickerId.value = router.currentRoute.params.id;
        }

        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "pack_image", "sticker_packs");
        const { storeSticker, updateSticker, showSticker } = useStickerList();
        // Register module

        const onSubmit = async () => {
            if (form.value.thumbnail) {
                delete form.value.thumbnail;
            }
            if (fileForm.value.file) {
                await storeFile();
            }
            if (stickerId.value) {
                updateSticker(stickerId.value, form.value).then((res) => {
                    router.push({ name: "apps-sticker-pack-list" });
                });
            } else {
                storeSticker(form.value).then((res) => {
                    router.push({ name: "apps-sticker-pack-list" });
                });
            }
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        // fill Form When edit page
        const showData = async () => {
            const response = await showSticker(stickerId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
            console.log(form.value);
        };

        if (stickerId.value) {
            showData();
        }

        return {
            form,
            fileForm,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            stickerId,
            updateSticker,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
